import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import config from 'app/config'
import { EventBus } from 'app/provider/event-bus'
import { Platform } from 'react-native'
import { apolloCache } from './apollo-cache'

type CreateApolloClientOptions = {
  token?: string
  previewToken?: string
  cache?: ApolloCache<NormalizedCacheObject>
}

const isWeb = Platform.OS === 'web'
const isWebSSR = isWeb && typeof window === 'undefined'

// If we're on the server use cms full url, otherwise use proxy
const webCmsUrl = isWebSSR ? config.cmsUrl : config.webCmsUrl
const cmsLink = new HttpLink({ uri: isWeb ? webCmsUrl : config.cmsUrl })

// Auth errors
const authErrors = ['You are not allowed to perform this action.', 'Unauthorized']


const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  ; (graphQLErrors || []).forEach(({ message, locations, path }) =>
    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
  )

  // Check to see if we have an auth error
  const authError = graphQLErrors?.some((error) => authErrors.includes(error.message))

  if (authError) {
    EventBus.emitOnce('logOut')
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const previewTokenLink = (previewToken: string) =>
  new ApolloLink((operation, forward) => {
    if (operation.variables?.draft) {
      operation.setContext(({ headers }) => ({
        headers: {
          ...headers,
          'x-preview-token': previewToken,
        },
      }))
    }
    return forward(operation)
  })

const createLinks = (options: Pick<CreateApolloClientOptions, 'token' | 'previewToken'>) => {
  const links = [errorLink]

  links.push(new RetryLink({
    delay: {
      initial: 300,
      max: 4000,
    },
    attempts: {
      max: 3,
    }
  }))

  // Add auth middleware if token is present
  if (options.token) {
    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers }) => ({
        headers: {
          authorization: `JWT ${options.token}`,
          ...headers,
        },
      }))
      return forward(operation)
    })
    links.push(authMiddleware)
  }

  if (options.previewToken) {
    links.push(previewTokenLink(options.previewToken))
  }

  links.push(cmsLink)

  return from(links)
}

/**
 * Updates the auth token in the apollo client after client is created
 */
export const updateAuthToken = (token: string | null, apolloClient: ApolloClient<any>) => {
  const links = createLinks({ token: token || undefined })
  apolloClient.setLink(links)
}

export const createApolloClient = ({
  token,
  previewToken,
  cache = apolloCache,
}: CreateApolloClientOptions) => {
  const headers = {
    'x-platform': Platform.OS,
  }

  const client = new ApolloClient({
    link: createLinks({ token, previewToken }),
    // SSR mode is enabled only on web and needs to be consistent between server and client
    ssrMode: isWebSSR,
    headers,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-first"
      },
      query: {
        fetchPolicy: "cache-first"
      },
    }
  })

  return client
}
